import React from "react";
import {
  ElectionPrediction,
  getCandidateImage,
  getPredictionChangeDisplay,
} from "../../utils";
import clsx from "clsx";

interface ElectionPredictionCardProps {
  prediction: ElectionPrediction;
}

const ElectionPredictionCard: React.FC<ElectionPredictionCardProps> = ({
  prediction: { name, latestPercentage, change },
}) => {
  const latestNumber = (latestPercentage * 100).toFixed(2);

  const changeDisplay = getPredictionChangeDisplay(change);

  return (
    <div className="flex flex-col text-center">
      <div className="px-10 py-4 text-xl bg-gray-600 text-white border border-gray-600 rounded-t-lg font-medium tracking-wide">
        {name}
      </div>
      <div className="px-10 py-6 border border-gray-300 border-t-0 rounded-b-lg">
        <img
          src={getCandidateImage(name)}
          alt={name}
          className="w-24 h-24 mx-auto rounded-full"
        />
        <div className="mt-6 text-5xl font-bold">{latestNumber}%</div>
        <div
          className={clsx(
            "flex flex-row items-center mt-4 text-2xl justify-center",
            changeDisplay.color,
          )}
        >
          {/* {changeDisplay.icon} */}
          <div className="ml-0.5 relative font-semibold">
            {changeDisplay.text}
            <sup className="text-xs absolute top-0 -right-1.5 text-gray-400">
              *
            </sup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectionPredictionCard;
