import React from "react";
import clsx from "clsx";
import ReactMarkdown from "react-markdown";

interface MarkdownDisplayProps {
  markdownHtml: string;
  className?: string;
}

const MarkdownDisplay: React.FC<MarkdownDisplayProps> = ({
  markdownHtml,
  className,
}) => {
  return (
    <ReactMarkdown
      children={markdownHtml}
      linkTarget={"_blank"}
      className={clsx(
        `prose max-w-none prose-invert text-gray-800 lg:text-lg leading-relaxed`,
        className,
      )}
    ></ReactMarkdown>
  );
};

export default MarkdownDisplay;
