import React from "react";
import {
  PredictionResult,
  getCandidateImage,
  getPredictionChangeDisplay,
} from "../../utils";
import clsx from "clsx";

interface ElectionPredictionMobileCardProps {
  prediction: PredictionResult;
}

const ElectionPredictionMobileCard: React.FC<
  ElectionPredictionMobileCardProps
> = ({ prediction }) => {
  return (
    <div className="flex flex-col text-center">
      <div
        className="py-3 bg-gray-600 text-white border border-gray-600 rounded-t-lg 
        font-medium tracking-wide xs:text-lg"
      >
        Election Prediction
      </div>
      <div className="py-6 border border-gray-300 border-t-0 rounded-b-lg space-y-6">
        {Object.entries(prediction).map(([candidate, data]) => (
          <div
            key={candidate}
            className="flex flex-row justify-center space-x-3 px-1"
          >
            <div className="flex flex-col justify-center">
              <img
                src={getCandidateImage(candidate)}
                alt={candidate}
                className="w-16 h-16 xs:w-20 xs:h-20 sm:w-24 sm:h-24 rounded-full"
              />
            </div>
            <div className="flex flex-col">
              <div className="text-sm xs:text-base font-medium">
                {candidate}
              </div>
              <div className="mt-1.5">
                <div className="text-2xl xs:text-3xl sm:text-4xl font-bold">
                  {(data.latestPercentage * 100).toFixed(2)}%
                </div>
                <div
                  className={clsx(
                    "mt-1 relative text-lg xs:text-xl font-semibold",
                    getPredictionChangeDisplay(data.change).color,
                  )}
                >
                  {getPredictionChangeDisplay(data.change).text}
                  <sup className="text-xs absolute top-0 right-1.5 text-gray-400">
                    *
                  </sup>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ElectionPredictionMobileCard;
